<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Application Statistics</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-report-by">
                                    <template v-slot:label>
                                        <span class="text-danger">*</span> Application Counts By:
                                    </template>
                                    <b-input-group size="sm">
                                        <b-select id="search-report-by"
                                                  v-model="reportByFilter"
                                                  :options="reportByOptions"
                                                  :state="fieldState('reportByFilter')">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="reportByFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="applied-date-range">
                                    <template v-slot:label>
                                        <span class="text-danger">*</span> Applied Date Range:
                                    </template>
                                    <picker-of-dates id="applied-date-range"
                                                     v-model="appliedDateRange"
                                                     format="M/d/yyyy"
                                                     :state="fieldState('appliedDateRange')"
                                                     range clearable/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import PickerOfDates from '@/components/shared/PickerOfDates';
import download from '@/util/downloader';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import {errorModalOptions} from "../../../util/formatters";

@Component({
    components: {
        PickerOfDates
    }
})
export default class ApplicationStatistics extends Vue {

    reportByFilter = null;
    appliedDateRange = [null,null];

    showState = false;

    fieldState(field) {
        if (field.includes("Filter")) {
            return this.showState ? !!this[field] : undefined;
        } else {
            return this.showState ? !!this[field][0] : undefined;
        }
    }

    get reportByOptions() {
        return [{
            value: 'Contractor',
            text: 'Contractor'
        }, {
            value: 'Contractor State',
            text: 'Contractor State/Province'
        }, {
            value: 'Contractor Type',
            text: 'Contractor Type'
        }, {
            value: 'District with Council Breakdown',
            text: 'Applicant District with Council Breakdown'
        }, {
            value: 'Council with Local Breakdown',
            text: 'Applicant Council with Local Union Breakdown'
        }];
    }

    clearFilters() {
        this.reportByFilter = null;
        this.appliedDateRange = [null,null];
        this.showState = false;
    }

    get parameters() {
        const params = {
            reportBy: this.reportByFilter,
            applicationRange: this.appliedDateRange
        };
        return params;
    }

    async getCsv() {
        if (!this.isReady()) {
            return;
        }
        try {
            await download('POST', '/api/v1/export/application-statistics', JSON.stringify(this.parameters));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async getPdf() {
        if (!this.isReady()) {
            return;
        }
        try {
            await download('POST', '/api/v1/report/application-statistics', JSON.stringify(this.parameters));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    isReady() {
        if (!this.reportByFilter || !this.appliedDateRange[0]) {
            this.showState = true;
            return false;
        }
        return true;
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Application Statistics', null, true));
    }

}
</script>


<style scoped>

</style>
